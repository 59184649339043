import { ApiError, getJson, type Options } from "#app/lib/fetchClient";
import { logError } from "#app/lib/logger";
import { GATEWAY_PATHS } from "../api-utils";
import { type PageStatuses } from "./model-types";

export type GetMessagePageData = PageStatuses;

export async function getMessagePage(
  params: Pick<Options, "signal">,
): Promise<GetMessagePageData> {
  try {
    // Call endpoint
    const data = await getJson<GetMessagePageData>(
      `${GATEWAY_PATHS.bolAuthenticationService}/Message/Page`,
      { signal: params.signal },
    );

    // Success
    return data;
  } catch (err) {
    if (err instanceof ApiError) {
      if (err.code === 503) {
        logError("Authentication Service is unavailable.");
        return [{
          name: "Sign-In",
          path: "/sign-in",
          statusMessage: "Service Unavailable.",
          isAvailable: false,
        }];
      }
    }
    throw err;
  }
}
