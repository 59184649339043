import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";
import { type Sectors } from "./model-types";

export type GetResearchHeadlinesSectorsData = Sectors;

export async function getResearchHeadlinesSectors(
  params: Pick<Options, "signal">,
): Promise<GetResearchHeadlinesSectorsData> {
  // Call endpoint
  const data = await getJson<GetResearchHeadlinesSectorsData>(
    `${GATEWAY_PATHS.externalBairdResearchService}/ResearchHeadlines/Sectors`,
    { signal: params.signal },
  );

  // Success
  return data;
}
