import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";
import { type HouseholdSso } from "./model-types";

export type GetHouseholdSsoByIdParams = {
  householdId: string;
};

export type GetHouseholdSsoByIdData = HouseholdSso;

export async function getHouseholdSsoById(
  params: GetHouseholdSsoByIdParams & Pick<Options, "signal">,
): Promise<GetHouseholdSsoByIdData> {
  // Parse incoming
  const { householdId } = parseParams(params);

  // Call endpoint
  const data = await getJson<GetHouseholdSsoByIdData>(
    `${GATEWAY_PATHS.externalFinancialPlanService}/Household/${householdId}/SSO`,
    { signal: params?.signal },
  );

  // Success
  return data;
}

function parseParams(params: GetHouseholdSsoByIdParams) {
  const householdId = encodeURIComponent(params.householdId);
  return { householdId };
}
