import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";
import { type DocumentMetaData } from "../shared-document-model-types";

export type GetFinancialPlanDocumentsParams = {
  householdId?: string | undefined;
  beginDate?: string | undefined;
  endDate?: string | undefined;
};

export type GetFinancialPlanDocumentsData = DocumentMetaData;

export async function getFinancialPlanDocuments(
  params: GetFinancialPlanDocumentsParams & Pick<Options, "signal">,
): Promise<GetFinancialPlanDocumentsData> {
  // Parse incoming
  const searchParams = params ? parseParams(params) : "";

  // Call endpoint
  const data = await getJson<GetFinancialPlanDocumentsData>(
    `${GATEWAY_PATHS.externalFinancialPlanService}/FinancialPlan/Documents${searchParams}`,
    { signal: params?.signal },
  );

  // Success
  return data;
}

function parseParams(params: GetFinancialPlanDocumentsParams) {
  // Destructure params
  const { householdId, beginDate, endDate } = params;

  // Build urlSearchParams
  const urlSearchParams = new URLSearchParams();
  if (householdId) {
    urlSearchParams.set("householdId", householdId);
  }
  if (beginDate) {
    urlSearchParams.set("beginDate", beginDate);
  }
  if (endDate) {
    urlSearchParams.set("endDate", endDate);
  }

  // Create searchParams string
  const searchParams =
    urlSearchParams.size > 0 ? `?${urlSearchParams.toString()}` : "";

  return searchParams;
}
