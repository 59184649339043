import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";
import { type FinancialPlanningStatus } from "./model-types";

export type GetFinancialPlanStatusData = FinancialPlanningStatus;

export async function getFinancialPlanStatus(
  params: Pick<Options, "signal">,
): Promise<GetFinancialPlanStatusData> {
  // Call endpoint
  const data = await getJson<GetFinancialPlanStatusData>(
    `${GATEWAY_PATHS.externalFinancialPlanService}/FinancialPlan/Status`,
    { signal: params.signal },
  );

  // Success
  return data;
}
