import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";
import { type DocumentMetaData } from "../shared-document-model-types";

export type GetStatementsDocumentsParams = {
  beginDate?: string | undefined;
  endDate?: string | undefined;
  acctIndex?: number[] | undefined;
};

export type GetStatementsDocumentsData = DocumentMetaData;

export async function getStatementsDocuments(
  params: GetStatementsDocumentsParams & Pick<Options, "signal">,
): Promise<GetStatementsDocumentsData> {
  // Parse incoming
  const searchParams = params ? parseParams(params) : "";

  // Call endpoint
  const data = await getJson<GetStatementsDocumentsData>(
    `${GATEWAY_PATHS.externalStatementsService}/Statements/Documents${searchParams}`,
    { signal: params?.signal },
  );

  // Success
  return data;
}

function parseParams(params: GetStatementsDocumentsParams) {
  // Destructure params
  const { beginDate, endDate, acctIndex } = params;

  // Build urlSearchParams
  const urlSearchParams = new URLSearchParams();
  if (beginDate) {
    urlSearchParams.set("beginDate", beginDate);
  }
  if (endDate) {
    urlSearchParams.set("endDate", endDate);
  }
  if (acctIndex) {
    urlSearchParams.set("acctIndex", acctIndex.join(","));
  }

  // Create searchParams string
  const searchParams =
    urlSearchParams.size > 0 ? `?${urlSearchParams.toString()}` : "";

  return searchParams;
}
