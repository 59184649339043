import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";
import { type Analysts } from "./model-types";

export type GetResearchHeadlinesAnalystsData = Analysts;

export async function getResearchHeadlinesAnalysts(
  params: Pick<Options, "signal">,
): Promise<GetResearchHeadlinesAnalystsData> {
  // Call endpoint
  const data = await getJson<GetResearchHeadlinesAnalystsData>(
    `${GATEWAY_PATHS.externalBairdResearchService}/ResearchHeadlines/Analysts`,
    { signal: params.signal },
  );

  // Success
  return data;
}
