import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";
import { type Households } from "./model-types";

export type GetHouseholdsData = Households;

export async function getHouseholds(
  params: Pick<Options, "signal">,
): Promise<GetHouseholdsData> {
  // Call endpoint
  const data = await getJson<GetHouseholdsData>(
    `${GATEWAY_PATHS.externalFinancialPlanService}/Household`,
    { signal: params.signal },
  );

  // Success
  return data;
}
