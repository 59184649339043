import { getBlob, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";

export type GetFinancialPlanPdfByIdParams = {
  planId: number;
};

export type GetFinancialPlanPdfByIdData = Blob;

export async function getFinancialPlanPdfById(
  params: GetFinancialPlanPdfByIdParams & Pick<Options, "signal">,
): Promise<GetFinancialPlanPdfByIdData> {
  // Parse incoming
  const { planId } = parseParams(params);

  // Call endpoint
  const data = await getBlob(
    `${GATEWAY_PATHS.externalFinancialPlanService}/FinancialPlan/${planId}/pdf`,
    { signal: params.signal },
  );

  // Success
  return data;
}

function parseParams(params: GetFinancialPlanPdfByIdParams) {
  const planId = encodeURIComponent(params.planId);
  return { planId };
}
