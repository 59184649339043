import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";
import { type FinancialPlans } from "./model-types";

export type GetFinancialPlanParams = {
  householdId?: string | undefined;
};

export type GetFinancialPlanData = FinancialPlans;

export async function getFinancialPlan(
  params: GetFinancialPlanParams & Pick<Options, "signal">,
): Promise<GetFinancialPlanData> {
  // Parse incoming
  const searchParams = params ? parseParams(params) : "";

  // Call endpoint
  const data = await getJson<GetFinancialPlanData>(
    `${GATEWAY_PATHS.externalFinancialPlanService}/FinancialPlan${searchParams}`,
    { signal: params?.signal },
  );

  // Success
  return data;
}

function parseParams(params: GetFinancialPlanParams) {
  // Destructure params
  const { householdId } = params;

  // Build urlSearchParams
  const urlSearchParams = new URLSearchParams();
  if (householdId) {
    urlSearchParams.set("householdId", householdId);
  }

  // Create searchParams string
  const searchParams =
    urlSearchParams.size > 0 ? `?${urlSearchParams.toString()}` : "";

  return searchParams;
}
