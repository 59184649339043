import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";

export type GetResearchHeadlinesHasAccessData = boolean;

export async function getResearchHeadlinesHasAccess(
  params: Pick<Options, "signal">,
): Promise<GetResearchHeadlinesHasAccessData> {
  // Call endpoint
  const data = await getJson<GetResearchHeadlinesHasAccessData>(
    `${GATEWAY_PATHS.externalBairdResearchService}/ResearchHeadlines/HasAccess`,
    { signal: params.signal },
  );

  // Success
  return data;
}
