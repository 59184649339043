import { getBlob, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";

export type GetResearchHeadlinesDocumentParams = {
  headlineId: number;
};

export type GetResearchHeadlinesDocumentData = Blob;

export async function getResearchHeadlinesDocument(
  params: GetResearchHeadlinesDocumentParams & Pick<Options, "signal">,
): Promise<GetResearchHeadlinesDocumentData> {
  // Parse incoming
  const searchParams = parseParams(params);

  // Call endpoint
  const data = await getBlob(
    `${GATEWAY_PATHS.externalBairdResearchService}/ResearchHeadlines/Document${searchParams}`,
    { signal: params.signal },
  );

  // Success
  return data;
}

function parseParams(params: GetResearchHeadlinesDocumentParams) {
  // Destructure params
  const { headlineId } = params;

  // Build urlSearchParams
  const urlSearchParams = new URLSearchParams({ headlineId: `${headlineId}` });

  // Create searchParams string
  const searchParams =
    urlSearchParams.size > 0 ? `?${urlSearchParams.toString()}` : "";

  return searchParams;
}
