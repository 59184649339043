import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";
import { type PagedResearchHeadline } from "./model-types";

export type GetResearchHeadlinesParams = {
  tickerSymbol?: string;
  headlineText?: string;
  deptInd?: string;
  deptSect?: string;
  analystId?: string;
  ratingCompany?: "Baird"; // Defaulted from: "Morningstar" | "Baird" | "Valueline";
  ratingKey?: string;
  suitability?: string;
  page?: number;
  pageSize?: number;
};

export type GetResearchHeadlinesData = PagedResearchHeadline;

export async function getResearchHeadlines(
  params: GetResearchHeadlinesParams & Pick<Options, "signal">,
): Promise<GetResearchHeadlinesData> {
  // Parse incoming
  const searchParams = params ? parseParams(params) : "";

  // Call endpoint
  const data = await getJson<GetResearchHeadlinesData>(
    `${GATEWAY_PATHS.externalBairdResearchService}/ResearchHeadlines${searchParams}`,
    { signal: params.signal },
  );

  // Success
  return data;
}

function parseParams(params: GetResearchHeadlinesParams) {
  // Destructure params
  const {
    tickerSymbol,
    headlineText,
    deptInd,
    deptSect,
    analystId,
    ratingCompany,
    ratingKey,
    suitability,
    page,
    pageSize,
  } = params;

  // Build urlSearchParams
  const urlSearchParams = new URLSearchParams();
  if (tickerSymbol) {
    urlSearchParams.set("tickerSymbol", tickerSymbol);
  }
  if (headlineText) {
    urlSearchParams.set("headlineText", headlineText);
  }
  if (deptInd) {
    urlSearchParams.set("deptInd", deptInd);
  }
  if (deptSect) {
    urlSearchParams.set("deptSect", deptSect);
  }
  if (analystId) {
    urlSearchParams.set("analystId", analystId);
  }
  if (ratingCompany) {
    urlSearchParams.set("ratingCompany", ratingCompany);
  }
  if (ratingKey) {
    urlSearchParams.set("ratingKey", ratingKey);
  }
  if (suitability) {
    urlSearchParams.set("suitability", suitability);
  }
  if (page != null) {
    urlSearchParams.set("page", `${page}`);
  }
  if (pageSize && pageSize > 0) {
    urlSearchParams.set("pageSize", `${pageSize}`);
  }

  // Create searchParams string
  const searchParams =
    urlSearchParams.size > 0 ? `?${urlSearchParams.toString()}` : "";

  return searchParams;
}
