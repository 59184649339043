import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";
import { type MonteCarlo } from "./model-types";

export type GetHouseholdSuccessMeterByIdParams = {
  householdId: string;
};

export type GetHouseholdSuccessMeterByIdData = MonteCarlo;

export async function getHouseholdSuccessMeterById(
  params: GetHouseholdSuccessMeterByIdParams & Pick<Options, "signal">,
): Promise<GetHouseholdSuccessMeterByIdData> {
  // Parse incoming
  const { householdId } = parseParams(params);

  // Call endpoint
  const data = await getJson<GetHouseholdSuccessMeterByIdData>(
    `${GATEWAY_PATHS.externalFinancialPlanService}/Household/${householdId}/SuccessMeter`,
    { signal: params?.signal },
  );

  // Success
  return data;
}

function parseParams(params: GetHouseholdSuccessMeterByIdParams) {
  const householdId = encodeURIComponent(params.householdId);
  return { householdId };
}
