import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";
import { type FactsetSnapQuoteResponse } from "./model-types";

export type GetQuotesParams = {
  assetIDs: string[];
};

export type GetQuotesData = FactsetSnapQuoteResponse;

async function getQuotesRequest(
  params: GetQuotesParams & Pick<Options, "signal">,
): Promise<GetQuotesData> {
  // Parse incoming
  const searchParams = parseParams(params);

  // Call endpoint
  const data = await getJson<GetQuotesData>(
    `${GATEWAY_PATHS.externalMarketResearchService}/Quotes${searchParams}`,
    { signal: params.signal },
  );

  // Success
  return data;
}

export async function getQuotes(
  params: GetQuotesParams & Pick<Options, "signal">,
): Promise<GetQuotesData> {
  const perChunk = 20;
  const chunkedAssetIds: string[][] = [];
  for (let i = 0, j = params.assetIDs.length; i < j; i += perChunk) {
    chunkedAssetIds.push(params.assetIDs.slice(i, i + perChunk));
  }

  const results = await Promise.all(chunkedAssetIds.map((assetIDs) => {
    return getQuotesRequest({ assetIDs });
  }));

  return {
    data: results.flatMap((result) => result.data)
  }
}

function parseParams(params: GetQuotesParams) {
  // Destructure params
  const { assetIDs } = params;

  // Build urlSearchParams
  const urlSearchParams = new URLSearchParams({
    assetIDs: assetIDs.map((el) => `US:${el}`).join(","),
  });

  // Create searchParams string
  const searchParams =
    urlSearchParams.size > 0 ? `?${urlSearchParams.toString()}` : "";

  return searchParams;
}
