import { createQueryKeyStore } from "@lukemorales/query-key-factory";
import { getMessagePage } from "./bol-authentication-service/get-message-page";
import { getSaml } from "./bol-authentication-service/get-saml";
import {
  getResearchHeadlines,
  type GetResearchHeadlinesParams,
} from "./external-baird-research-service/get-research-headlines";
import { getResearchHeadlinesAnalysts } from "./external-baird-research-service/get-research-headlines-analysts";
import {
  getResearchHeadlinesDocument,
  type GetResearchHeadlinesDocumentParams,
} from "./external-baird-research-service/get-research-headlines-document";
import { getResearchHeadlinesHasAccess } from "./external-baird-research-service/get-research-headlines-has-access";
import { getResearchHeadlinesRatings } from "./external-baird-research-service/get-research-headlines-ratings";
import { getResearchHeadlinesSectors } from "./external-baird-research-service/get-research-headlines-sectors";
import { getResearchHeadlinesSuitabilities } from "./external-baird-research-service/get-research-headlines-suitabilities";
import { getAccounts } from "./external-bol-account-service/get-accounts";
import { getSharedSubscribed } from "./external-bol-account-service/get-shared-subscribed";
import { getSharedSubscribers } from "./external-bol-account-service/get-shared-subscribers";
import {
  getSharedSubscribersByAccountIndex,
  type GetSharedSubscribersByAccountIndexParams,
} from "./external-bol-account-service/get-shared-subscribers-by-account-index";
import { getSharingInvites } from "./external-bol-account-service/get-sharing-invites";
import { getSharingTermsAndConditionsOwner } from "./external-bol-account-service/get-sharing-terms-and-conditions-owner";
import { getSharingTermsAndConditionsViewer } from "./external-bol-account-service/get-sharing-terms-and-conditions-viewer";
import { getMandatoryActivity } from "./external-bol-notification-service/get-mandatory-activity";
import {
  getNotification,
  type GetNotificationParams,
} from "./external-bol-notification-service/get-notification";
import {
  getPreclientRegistrationVerifyInviteCode,
  type GetPreclientRegistrationVerifyInviteCodeParams,
} from "./external-bol-registration-service/get-preclient-registration-verify-invite-code";
import { getRegistrationOutage } from "./external-bol-registration-service/get-registration-outage";
import { getEBillSso } from "./external-cash-management-service/get-ebill-sso";
import { getUserEligibility as getUserEligibilityCashManagement } from "./external-cash-management-service/get-user-eligibility";
import {
  getClientCommunications,
  type GetClientCommunicationsParams,
} from "./external-clients-communications-service/get-client-communications";
import {
  getClientCommunicationsDocuments,
  type GetClientCommunicationsDocumentsParams,
} from "./external-clients-communications-service/get-client-communications-documents";
import {
  getClientCommunicationsPdf,
  type GetClientCommunicationsPdfParams,
} from "./external-clients-communications-service/get-client-communications-pdf";
import {
  getConfirmations,
  type GetConfirmationsParams,
} from "./external-confirmations-service/get-confirmations";
import {
  getConfirmationsPdf,
  type GetConfirmationsPdfParams,
} from "./external-confirmations-service/get-confirmations-pdf";
import {
  getSettings,
  type GetSettingsParams,
} from "./external-edelivery-service/get-settings";
import {
  getFinancialPlan,
  type GetFinancialPlanParams,
} from "./external-financial-plan-service/get-financial-plan";
import {
  getFinancialPlanDocuments,
  type GetFinancialPlanDocumentsParams,
} from "./external-financial-plan-service/get-financial-plan-documents";
import {
  getFinancialPlanPdfById,
  type GetFinancialPlanPdfByIdParams,
} from "./external-financial-plan-service/get-financial-plan-pdf-by-id";
import { getFinancialPlanStatus } from "./external-financial-plan-service/get-financial-plan-status";
import {
  getHouseholdSsoById,
  type GetHouseholdSsoByIdParams,
} from "./external-financial-plan-service/get-household-sso-by-id";
import {
  getHouseholdSuccessMeterById,
  type GetHouseholdSuccessMeterByIdParams,
} from "./external-financial-plan-service/get-household-success-meter-by-id";
import { getHouseholds } from "./external-financial-plan-service/get-households";
import {
  getAutocomplete,
  type GetAutocompleteParams,
} from "./external-market-research-service/get-autocomplete";
import {
  getQuotes,
  type GetQuotesParams,
} from "./external-market-research-service/get-quotes";
import {
  getAddresses,
  type GetAddressesParams,
} from "./external-pwm-address-service/get-addresses";
import {
  getStatements,
  type GetStatementsParams,
} from "./external-statements-service/get-statements";
import {
  getStatementsDocuments,
  type GetStatementsDocumentsParams,
} from "./external-statements-service/get-statements-documents";
import { getStatementsLatestAvailableDate } from "./external-statements-service/get-statements-latest-available-date";
import {
  getStatementsPdf,
  type GetStatementsPdfParams,
} from "./external-statements-service/get-statements-pdf";
import { getTaxDocumentTypes } from "./external-tax-documents-service/get-tax-document-types";
import {
  getTaxDocuments,
  type GetTaxDocumentsParams,
} from "./external-tax-documents-service/get-tax-documents";
import {
  getTaxDocumentsSinglePdf,
  type GetTaxDocumentsSinglePdfParams,
} from "./external-tax-documents-service/get-tax-documents-single-pdf";
import { getPortalSso } from "./external-trust-service/get-portal-sso";
import { getUserEligibility as getUserEligibilityTrust } from "./external-trust-service/get-user-eligibility";
import { getAccountGroups } from "./external-user-profile-service/get-account-groups";
import { getMetaData } from "./external-user-profile-service/get-metadata";
import { getAccountGroupsPortfolios } from "./pwm-portfolio-service/get-accounts-groups-portfolios";
import {
  getAssetAllocations,
  type GetAssetAllocationsParams,
} from "./pwm-portfolio-service/get-asset-allocations";
import {
  getHistoricalValues,
  type GetHistoricalValuesParams,
} from "./pwm-portfolio-service/get-historical-values";
import {
  getPortfolio,
  type GetPortfolioParams,
} from "./pwm-portfolio-service/get-portfolio";
import {
  getRecentActivity,
  type GetRecentActivityParams,
} from "./pwm-portfolio-service/get-recent-activity";
import {
  getWatchlistByName,
  type GetWatchlistByNameParams,
} from "./pwm-portfolio-service/get-watchlist-by-name";
import { getWatchlistNames } from "./pwm-portfolio-service/get-watchlist-names";

/**
 * All query keys in one file to:
 * - Prevent cache pollution/conflicts/duplications
 * - Expose best-practice naming and patterns
 *
 * Make sure to alpha-sort keys for better DX.
 */
export const allQueryKeys = createQueryKeyStore({
  bolAuthenticationService: {
    messagePage: {
      queryKey: null,
      queryFn: ({ signal }) => getMessagePage({ signal }),
    },
    saml: {
      queryKey: null,
      queryFn: ({ signal }) => getSaml({ signal }),
    },
  },

  externalBairdResearchService: {
    researchHeadlinesAnalysts: {
      queryKey: null,
      queryFn: ({ signal }) => getResearchHeadlinesAnalysts({ signal }),
    },
    researchHeadlinesDocument: (
      params: GetResearchHeadlinesDocumentParams,
    ) => ({
      queryKey: [params],
      queryFn: ({ signal }) =>
        getResearchHeadlinesDocument({ ...params, signal }),
    }),
    researchHeadlinesHasAccess: {
      queryKey: null,
      queryFn: ({ signal }) => getResearchHeadlinesHasAccess({ signal }),
    },
    researchHeadlinesRatings: {
      queryKey: null,
      queryFn: ({ signal }) => getResearchHeadlinesRatings({ signal }),
    },
    researchHeadlinesSectors: {
      queryKey: null,
      queryFn: ({ signal }) => getResearchHeadlinesSectors({ signal }),
    },
    researchHeadlinesSuitabilities: {
      queryKey: null,
      queryFn: ({ signal }) => getResearchHeadlinesSuitabilities({ signal }),
    },
    researchHeadlines: (params?: GetResearchHeadlinesParams) => ({
      queryKey: [params],
      queryFn: ({ signal }) => getResearchHeadlines({ ...params, signal }),
    }),
  },

  externalBolAccountService: {
    accounts: {
      queryKey: null,
      queryFn: ({ signal }) => getAccounts({ signal }),
    },
    sharedSubscribed: {
      queryKey: null,
      queryFn: ({ signal }) => getSharedSubscribed({ signal }),
    },
    sharedSubscribers: {
      queryKey: null,
      queryFn: ({ signal }) => getSharedSubscribers({ signal }),
    },
    sharedSubscribersByAccountIndex: (
      params: GetSharedSubscribersByAccountIndexParams,
    ) => ({
      queryKey: [params],
      queryFn: ({ signal }) =>
        getSharedSubscribersByAccountIndex({ ...params, signal }),
    }),
    sharingInvites: {
      queryKey: null,
      queryFn: ({ signal }) => getSharingInvites({ signal }),
    },
    sharingTermsAndConditionsOwner: {
      queryKey: null,
      queryFn: ({ signal }) => getSharingTermsAndConditionsOwner({ signal }),
    },
    sharingTermsAndConditionsViewer: {
      queryKey: null,
      queryFn: ({ signal }) => getSharingTermsAndConditionsViewer({ signal }),
    },
  },

  externalBolNotificationService: {
    mandatoryActivity: {
      queryKey: null,
      queryFn: ({ signal }) => getMandatoryActivity({ signal }),
    },
    notification: (params?: GetNotificationParams) => ({
      queryKey: [params],
      queryFn: ({ signal }) => getNotification({ ...params, signal }),
    }),
  },

  externalBolRegistrationService: {
    registrationOutage: {
      queryKey: null,
      queryFn: ({ signal }) => getRegistrationOutage({ signal }),
    },
    preclientRegistrationVerifyInviteCode: (
      params: GetPreclientRegistrationVerifyInviteCodeParams,
    ) => ({
      queryKey: [params],
      queryFn: ({ signal }) =>
        getPreclientRegistrationVerifyInviteCode({ ...params, signal }),
    }),
  },

  externalCashManagementService: {
    eBillSso: {
      queryKey: null,
      queryFn: ({ signal }) => getEBillSso({ signal }),
    },
    userEligibility: {
      queryKey: null,
      queryFn: ({ signal }) => getUserEligibilityCashManagement({ signal }),
    },
  },

  externalClientsCommunicationsService: {
    clientCommunications: (params: GetClientCommunicationsParams) => ({
      queryKey: [params],
      queryFn: ({ signal }) => getClientCommunications({ ...params, signal }),
    }),
    clientCommunicationsDocuments: (
      params?: GetClientCommunicationsDocumentsParams,
    ) => ({
      queryKey: [params],
      queryFn: ({ signal }) =>
        getClientCommunicationsDocuments({ ...params, signal }),
    }),
    clientCommunicationsPdf: (params: GetClientCommunicationsPdfParams) => ({
      queryKey: [params],
      queryFn: ({ signal }) =>
        getClientCommunicationsPdf({ ...params, signal }),
    }),
  },

  externalConfirmationsService: {
    confirmations: (params?: GetConfirmationsParams) => ({
      queryKey: [params],
      queryFn: ({ signal }) => getConfirmations({ ...params, signal }),
    }),
    confirmationsPdf: (params: GetConfirmationsPdfParams) => ({
      queryKey: [params],
      queryFn: ({ signal }) => getConfirmationsPdf({ ...params, signal }),
    }),
  },

  externalEDeliveryService: {
    settings: (params: GetSettingsParams) => ({
      queryKey: [params],
      queryFn: ({ signal }) => getSettings({ ...params, signal }),
    }),
  },

  externalFinancialPlanService: {
    financialPlanDocuments: (params?: GetFinancialPlanDocumentsParams) => ({
      queryKey: [params],
      queryFn: ({ signal }) => getFinancialPlanDocuments({ ...params, signal }),
    }),
    financialPlanPdfById: (params: GetFinancialPlanPdfByIdParams) => ({
      queryKey: [params],
      queryFn: ({ signal }) => getFinancialPlanPdfById({ ...params, signal }),
    }),
    financialPlanStatus: {
      queryKey: null,
      queryFn: ({ signal }) => getFinancialPlanStatus({ signal }),
    },
    financialPlan: (params?: GetFinancialPlanParams) => ({
      queryKey: [params],
      queryFn: ({ signal }) => getFinancialPlan({ ...params, signal }),
    }),
    householdSsoById: (params: GetHouseholdSsoByIdParams) => ({
      queryKey: [params],
      queryFn: ({ signal }) => getHouseholdSsoById({ ...params, signal }),
    }),
    householdSuccessMeterById: (
      params: GetHouseholdSuccessMeterByIdParams,
    ) => ({
      queryKey: [params],
      queryFn: ({ signal }) =>
        getHouseholdSuccessMeterById({ ...params, signal }),
    }),
    households: {
      queryKey: null,
      queryFn: ({ signal }) => getHouseholds({ signal }),
    },
  },

  externalFundTransferService: {},

  externalHeldawayService: {},

  externalMarketingService: {},

  externalMarketResearchService: {
    autocomplete: (params: GetAutocompleteParams) => ({
      queryKey: [params],
      queryFn: ({ signal }) => getAutocomplete({ ...params, signal }),
    }),
    quotes: (params: GetQuotesParams) => ({
      queryKey: [params],
      queryFn: ({ signal }) => getQuotes({ ...params, signal }),
    }),
  },

  externalPerformanceReportingService: {},

  externalPwmAddressService: {
    getAddresses: (params: GetAddressesParams) => ({
      queryKey: [params],
      queryFn: ({ signal }) => getAddresses({ ...params, signal }),
    }),
  },

  externalSecureDocumentService: {},

  externalStatementsService: {
    statements: (params?: GetStatementsParams) => ({
      queryKey: [params],
      queryFn: ({ signal }) => getStatements({ ...params, signal }),
    }),
    statementsDocuments: (params: GetStatementsDocumentsParams) => ({
      queryKey: [params],
      queryFn: ({ signal }) => getStatementsDocuments({ ...params, signal }),
    }),
    statementsLatestAvailableDate: {
      queryKey: null,
      queryFn: ({ signal }) => getStatementsLatestAvailableDate({ signal }),
    },
    statementsPdf: (params?: GetStatementsPdfParams) => ({
      queryKey: [params],
      queryFn: ({ signal }) => getStatementsPdf({ ...params, signal }),
    }),
  },

  externalTaxDocumentsService: {
    taxDocuments: (params?: GetTaxDocumentsParams) => ({
      queryKey: [params],
      queryFn: ({ signal }) => getTaxDocuments({ ...params, signal }),
    }),
    taxDocumentsSinglePdf: (params: GetTaxDocumentsSinglePdfParams) => ({
      queryKey: [params],
      queryFn: ({ signal }) => getTaxDocumentsSinglePdf({ ...params, signal }),
    }),
    taxDocumentTypes: {
      queryKey: null,
      queryFn: ({ signal }) => getTaxDocumentTypes({ signal }),
    },
  },

  externalTrustService: {
    portalSso: {
      queryKey: null,
      queryFn: ({ signal }) => getPortalSso({ signal }),
    },
    userEligibility: {
      queryKey: null,
      queryFn: ({ signal }) => getUserEligibilityTrust({ signal }),
    },
  },

  externalUserProfileService: {
    accountGroups: {
      queryKey: null,
      queryFn: ({ signal }) => getAccountGroups({ signal }),
    },
    metaData: {
      queryKey: null,
      queryFn: ({ signal }) => getMetaData({ signal }),
    },
  },

  pwmPortfolioService: {
    accountGroupsPortfolios: {
      queryKey: null,
      queryFn: ({ signal }) => getAccountGroupsPortfolios({ signal }),
    },
    assetAllocations: (params: GetAssetAllocationsParams) => ({
      queryKey: [params],
      queryFn: ({ signal }) => getAssetAllocations({ ...params, signal }),
    }),
    historicalValues: (params?: GetHistoricalValuesParams) => ({
      queryKey: [params],
      queryFn: ({ signal }) => getHistoricalValues({ ...params, signal }),
    }),
    portfolio: (params?: GetPortfolioParams) => ({
      queryKey: [params],
      queryFn: ({ signal }) => getPortfolio({ ...params, signal }),
    }),
    recentActivity: (params?: GetRecentActivityParams) => ({
      queryKey: [params],
      queryFn: ({ signal }) => getRecentActivity({ ...params, signal }),
    }),
    watchlistByName: (params: GetWatchlistByNameParams) => ({
      queryKey: [params],
      queryFn: ({ signal }) => getWatchlistByName({ ...params, signal }),
    }),
    watchlistNames: {
      queryKey: null,
      queryFn: ({ signal }) => getWatchlistNames({ signal }),
    },
  },
});
